export default {
	firestorePath: "reports",
	firestoreRefType: "collection",
	moduleName: "reports",
	statePropName: "data",
	namespaced: true,
	sync: {
		defaultValues: {
			status: "published", 
			visualisations: []
		},
		insertHook: function (updateStore, doc, store ) {
			doc.users = [store.state.auth.data.id];
			return updateStore(doc) 
		},
	},
	serverChange: {
		// createdHook: 
		addedHook: function(updateStore, doc, _, { dispatch }) {
			dispatch("visualisations/fetchByIds", doc.visualisations);
			return updateStore(doc)
		},
	},
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchMine({ dispatch, rootState }) {
			let id = rootState.auth.data.id;
			dispatch("fetchAndAdd", {
				clauses: {
					where: [["users", "array-contains", id]],
				},
			})		
		},
	},
};
