<template>
    <v-sheet>
        <template v-if="content">
            <h2 v-if="content.title" class="mb-5">{{content.title}}</h2>
            <div v-html="renderedContent"></div>
        </template>
    </v-sheet>
</template>
<script>
    export default {
        name: "RenderContent",
        props: {
            id: {}
        },
        computed: {
            content(){
                return this.$store.state.content.data[this.id]
            },
            renderedContent(){
                if( !this.content ){
                    return "";
                }
                return this.content.html
            }
        },
        created(){
            this.$store.dispatch("content/fetchById", this.id)
        }

    }
</script>