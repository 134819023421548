export default {
	firestorePath: "categories/jobFunction",
	firestoreRefType: "doc",
	moduleName: "jobFunctions",
	statePropName: "data",
	namespaced: true,
	serverChange: {
		modifiedHook: function(updateStore, doc, _, { dispatch }) {
			dispatch("categoryOptions/fetchByIds", doc.options);
            return updateStore(doc)
		},
	},
	getters: {
        options(state, _, rootState){
            if( !state.data.options ){
                return []
            }
            return state.data.options.map( a => {
                return rootState.categoryOptions.data[a]
            }).filter( a => a )
        }
    },
	state: {},
	mutations: {},
	actions: {},
};
