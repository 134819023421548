export default {
	firestorePath: "questionTemplates",
	firestoreRefType: "collection",
	moduleName: "questionTemplates",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {
	},
	mutations: {},
	actions: {},
};
