export default {
	firestorePath: "visualisations",
	firestoreRefType: "collection",
	moduleName: "visualisations",
	statePropName: "data",
	namespaced: true,
	state: {},
	sync: {
		defaultValues: {
			status: "published",
		},
	},
	getters: {},
	mutations: {},
	actions: {},
};
