export default {
	firestorePath: "indicators",
	firestoreRefType: "collection",
	moduleName: "indicators",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {
	},
	mutations: {},
	actions: {},
};
