import Vue from "vue";
export default {
	firestorePath: "sections",
	firestoreRefType: "collection",
	moduleName: "sections",
	statePropName: "data",
	namespaced: true,
	state: {
		filter: [],
	},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			if( doc.status == "published" ){
				if( doc.questions ){
					dispatch("questions/fetchByIds", doc.questions)
				}
				return updateStore(doc)
			}
		},
	},
	getters: {},
	mutations: {
		FILTER(state , sections) {
			if( sections.length ){
				Vue.set(state, "filter", sections);
			}
		},
		CLEAR_FILTER( state ) {
			Vue.set(state, "filter", []);
		},
	},
	actions: {},
};
